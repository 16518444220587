<template>
  <HeaderHome :organizer_id="event.organizer.id" :organizer_slug="event.organizer.slug" :image="event.image"/>
  <div class="container-fluid body">
    <div class="container">
      <Loader :show="loading"/>
      <div class="row body-box">
        <div class="col-md-12" ref="lote">
          <h1>{{ event.name }}</h1>
          <h2 v-if="order.user_id"><b>*Compra para outro Usuário TKX</b><br></h2>
          <br>
        </div>

        <form @submit.prevent="submit" ref="form">

          <div class="col-md-12 lotes" v-if="lotes.length > 1 || (lotes.length == 1 && lotes[0].disabled == true)">
            <div class="row">
              <div class="col-xs-12 mb-2">
                <h2> Escolha o Lote desejado:</h2>
                <h4 style="color: red;" > *Caso sua faixa etária não esteja visível isso significa que não há mais vagas na mesma.</h4>
                <br>
              </div>
            </div>
            <div class="row">
              <div :class="['col-md-4','col-sm-6','inscricoes',order.lote_id == lote.id ? 'selected':'']" v-for="(lote,index) in lotes" :key="index">

                <div class="card card-default" @click="select_lote(lote.id,index)" v-if="lote.disabled == false">
                  <div class="card-body text-center">
                    <p>{{ lote.name }}</p>
                  </div>
                </div>

                <div class="card card-default disabled-card disabled" v-if="lote.disabled == true">
                  <div class="card-body text-center">
                    <p style="padding-bottom: 0px">{{ lote.name }}</p>
                    <small style="font-size: 12px;">Sua faixa etária não permite se inscrever nesse lote.</small>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div class="col-md-12 lotes" v-if="lotes.length == 0 && this.loading == false ">
            <div class="row">
              <div class="col-xs-12 mb-2">
                <h2> Escolha o Lote desejado:</h2>
              </div>
            </div>
            <div class="row">
              <div :class="['col-md-12','inscricoes']">
                <div class="card card-default">
                  <div class="card-body text-center">
                    <p>Não existem lotes disponíveis no momento.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div :class="['col-md-12','codigo','mb-4',codigo_valid == false ? 'invalido':'']" v-if="lote_index != null && lotes[lote_index].has_codes" ref="codigo">
            <div class="row">
              <div class="col-xs-12 mb-2">
                <h2> {{ lotes[lote_index].text_code != null ? lotes[lote_index].text_code : "Entre com o Codigo para o Lote :" }}</h2>
              </div>
            </div>
            <div class="row">
              <div :class="['col-md-4','col-sm-6','col-xs-8']">
                <input type="text" name="code" v-model="codigo" class="form-control">
              </div>
              <div :class="['col-md-2','col-sm-2','col-xs-2', 'text-center' , 'mt-2']">
                <button class="btn btn-danger" @click.prevent="valida_code">Validar</button>
              </div>
            </div>
          </div>


          <div class="col-md-12 categoria" v-show="categorias.length && show_categorias" ref="categorias">
            <div class="row">
              <div class="col-xs-12 mb-2">
                <h2> Escolha a Categoria desejada:</h2>
              </div>
            </div>
            <div class="row">
              <div :class="['col-md-4','col-sm-6','inscricoes',order.modality_id == categoria.id ? 'selected':'']" v-for="(categoria,index) in categorias" :key="index">
                <div class="card card-default" @click="select_categoria(categoria.id)">
                  <div class="card-body text-center">
                    <p>{{ categoria.categoria }} - {{ categoria.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 kits" v-show="kits.length && show_kits" ref="kits">
            <div class="row">
              <div class="col-xs-12 mb-2">
                <h2> Escolha o KIT desejado:</h2>
              </div>
            </div>
            <div class="row">
              <div :class="['col-md-4','col-sm-6','inscricoes',order.kit_id == kit.id ? 'selected':'']" v-for="(kit,index) in kits" :key="index">
                <div class="card card-default" @click="select_kit(kit.id,index)">
                  <img class="card-img-top img-card-kit" :src="kit.image" :alt="kit.name">
                  <div class="card-body text-center">
                    <p>{{ kit.name }} - <span>R$ {{ new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2}).format(kit.price) }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 kits" v-show="kits_tamanhos.length" ref="kits_tamanhos">
            <div class="row">
              <div class="col-xs-12 mb-2">
                <h2> Escolha a o Tamanho:</h2>
              </div>
            </div>
            <div class="row">
              <div :class="['col-md-4','col-sm-6','inscricoes',order.tamanho == kit_tamanho ? 'selected':'']" v-for="(kit_tamanho,index) in kits_tamanhos" :key="index">
                <div class="card card-default" @click="select_tamanho(kit_tamanho)">
                  <div class="card-body text-center">
                    <p>{{ kit_tamanho }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 agendamentos" v-show="agendamentos.length && show_agendamento" ref="agendamentos">
            <div class="row">
              <div class="col-xs-12 mb-2">
                <h2> Escolha a Hora da Retirada do Kit:</h2>
              </div>
            </div>
            <div class="row">
              <div :class="['col-md-4','col-sm-6','inscricoes',order.schedule_id == agendamento.id ? 'selected':'']" v-for="(agendamento,index) in agendamentos" :key="index">
                <div class="card card-default" @click="select_agendamento(agendamento)">
                  <div class="card-body text-center">
                    <p>{{ agendamento.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-12 mt-4 mb-4 final" ref="final" v-show="end_selections && ((agendamentos.length == 0 && order.schedule_id == null) || (agendamentos.length != 0 && order.schedule_id != null)) ">
            <div class="row">
              <div class="col-md-6">
                <label>
                  <input type="checkbox" v-model="order.termos" required>
                  Declaro que li e aceito o <a href="#regulamento" @click.prevent="show_regulamentos">regulamento</a>.
                </label>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-primary btn-proximo">PRÓXIMO PASSO</button>
              </div>
            </div>
          </div>

        </form>

        <div class="modal fade in regulamentos" tabindex="-1" role="dialog" style="display: block;" v-if="modalregulation" @click="modalregulation= false">
          <div class="modal-dialog  modal-lg" role="document">
            <div class="modal-content" @click.stop="">
              <div class="modal-header text-center">
                <button type="button" class="close" @click="modalregulation= false" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">Regulamento</h4>
              </div>
              <div class="modal-body" v-html="regulation">
              </div>
            </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
        <div class="modal-backdrop fade in" v-if="modalregulation"></div>


        <Atalhos/>
      </div>
    </div>
  </div>

  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import {defineComponent} from "vue";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Atalhos from "@/components/Atalhos";
import Events from "@/api/Events";
import Loader from "../components/Loader";
import Auth from "@/api/Auth";
import Carrinho from "@/api/Carrinho";
import Orders from "../api/Orders";
import Swal from "sweetalert2";
import User from "../api/User";

export default defineComponent({
  name: "EscolhaKitPage",
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Newsletter,
    Atalhos,
    Loader,
  },
  computed: {
    modalidades: function () {
      let modalidades = "";
      this.event.modalities.map((item) => {
        modalidades = item.name + ', ' + modalidades;
      })
      return modalidades;
    }
  },
  data() {
    return {
      modalregulation: false,
      end_selections: false,
      regulation: "",
      id: '',
      slug: '',
      loading: true,
      event: {
        "id": 0,
        "subscription_end": "",
        "largada": "",
        "name": "",
        "slug": "",
        "local": "",
        "state": "",
        "city": "",
        "description": "",
        "kits": "",
        "course": "",
        "awards": "",
        "complementary": "",
        "organizer": {
          "id": 0,
          "razao_social": "",
          "slug": "",
          "cnpj": "",
          "telephone": ""
        },
        "modalities": [],
        "banners": [],
        "sponsor": [],
      },
      'user_age':0,
      'codigo': '',
      "codigo_valid": true,
      "categorias": [],
      "agendamentos": [],
      "lotes": [],
      'lote_index': null,
      "kits": [],
      "kits_tamanhos": [],
      "show_kits": false,
      "show_agendamento": false,
      "show_categorias":false,
      "need_agendamento":false,
      "order": {
        'schedule_id':null,
        'event_id': null,
        "lote_id": null,
        "modality_id": null,
        "kit_id": null,
        "codigo": '',
        "tamanho": null,
        "termos": false,
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.reportValidity();

      let itens_carrinho = Carrinho.get_itens();
      let result = itens_carrinho.filter((item)=>{
        if(item.event_id == this.order.event_id){
          if(this.order.user_id === item.user_id){
            return true;
          }else{
            return false;
          }
        }else{
            return false;
        }
      });

      if(result.length){
        Swal.fire({
          icon: 'error',
          title:'Você já possui esse evento em seu carrinho.',
          showConfirmButton: true,
        });
        return false;
      }
      this.loading = true;

      Carrinho.add_last_event(this.id,this.slug);
      Carrinho.add_item(this.order);
      if(window.localStorage.getItem('user_buy')){
         window.localStorage.removeItem('user_buy')
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
        this.$router.push('/carrinho/');
      }, 500);
    },
    show_regulamentos() {
      if (this.regulation == "") {
        Events.get_regulamento(this.slug).then((request) => {
          if (request.status == 200) {
            this.modalregulation = true;
            this.regulation = request.data.regulation;
          }
        });
      } else {
        this.modalregulation = true;
      }

    },
    select_agendamento(agendamento){
      this.order.schedule_id = agendamento.id;

      this.goto_scroll(this.$refs.final);
    },
    status(event) {
      let today = new Date();
      let start_date = new Date(event.subscription_start);
      let end_date = new Date(event.subscription_end);
      if (start_date <= today && end_date >= today) {
        return true;
      } else {
        return false;
      }
    },
    goto_scroll(element) {
      setTimeout(() => {
        this.$smoothScroll({
          offset: -100,
          scrollTo: element,
        });
      }, 500);
    },
    select_categoria(id) {

      this.order.termos = false;
      this.end_selections = false;
      this.order.tamanho = null;
      this.order.kit_id = null;
      this.show_kits = true;

      this.order.modality_id = id;
      this.goto_scroll(this.$refs.kits);
    },
    select_tamanho(tamanho) {
      this.order.termos = false;
      this.order.tamanho = tamanho;
      this.end_selections = true;
      this.show_agendamento = true;
      if(this.agendamentos.length){
        this.goto_scroll(this.$refs.agendamentos);
      }else{
        this.goto_scroll(this.$refs.final);
      }
    },
    select_lote(id, index) {

      this.lote_index = index;
      this.order.lote_id = id;

      this.codigo = "";

      this.show_kits = false;
      this.show_agendamento = false;
      this.codigo_valid = true;
      this.order.termos = false;
      this.end_selections = false;
      this.categorias = [];
      this.kits = [];
      this.order.kit_id = null;
      this.order.modality_id = null;

      this.order.tamanho = null;
      this.kits_tamanhos = [];

      if (this.lotes[index] && this.lotes[index].has_codes == false) {
        Events.get_categorias(this.order.event_id, Auth.token()).then((request) => {
          if (request.status == 200) {
            this.categorias = request.data;
            this.show_categorias = true;
            this.goto_scroll(this.$refs.categorias);
          }
        });
        Events.get_kits(this.order.lote_id, Auth.token()).then((request) => {
          if (request.status == 200) {
            this.kits = request.data;
          }
        });
      }
    },
    valida_code() {
      let Maratona = 438;
      let maratona_ref = 27;
      let Meia =441;
      let meia_ref = 28;
      let k12 = 442;
      let k12_ref = 29;

      Orders.validate_code(this.order.lote_id, this.codigo, Auth.token()).then((request) => {
        this.order.termos = false;
        this.end_selections = false;
        this.categorias = [];
        this.kits = [];
        this.order.kit_id = null;
        this.order.modality_id = null;

        this.order.tamanho = null;

        this.show_kits = false;
        this.show_agendamento = false;

        this.kits_tamanhos = [];


        if (request.status == 200 && request.data && request.data.status === true) {

          let carrinho = Carrinho.get_itens();
          let codigos_carrinho = carrinho.filter(item => item.codigo == this.codigo);


          if(request.data.quantity <= codigos_carrinho.length){
            this.codigo_valid = false;
            this.codigo = "";

            Swal.fire({
              icon: 'error',
              title:'Este código já está sendo utilizado em outro item no carrinho.',
              showConfirmButton: true,
            });

          }else{
            this.codigo_valid = true;
            this.order.codigo = this.codigo;

            Events.get_categorias(this.order.event_id, Auth.token()).then((request) => {
              if (request.status == 200) {
                this.categorias = request.data;

                if(this.order.lote_id == Maratona){
                  this.order.modality_id = maratona_ref;
                  this.show_kits = true;
                  this.goto_scroll(this.$refs.kits);
                }else if(this.order.lote_id == Meia){
                  this.order.modality_id = meia_ref;
                  this.show_kits = true;
                  this.goto_scroll(this.$refs.kits);
                }else if(this.order.lote_id == k12){
                  this.order.modality_id = k12_ref;
                  this.show_kits = true;
                  this.goto_scroll(this.$refs.kits);
                }else{

                  this.show_categorias = true;
                  this.goto_scroll(this.$refs.categorias);
                }


              }
            });
            Events.get_kits(this.order.lote_id, Auth.token()).then((request) => {
              if (request.status == 200) {
                this.kits = request.data;
              }
            });

          }


        } else {
          this.codigo_valid = false;
          this.codigo = "";

          Swal.fire({
            icon: 'error',
            title:'Código de validação não é valido.',
            showConfirmButton: true,
          });

        }
      });

    },
    select_kit(id, index) {

      this.order.termos = false;
      this.end_selections = false;
      this.order.tamanho = null;
      this.kits_tamanhos = [];

      this.order.kit_id = id;
      this.kits_tamanhos = (this.kits[index].options).split(',');

      this.goto_scroll(this.$refs.kits_tamanhos);

    },
    to_other_user(){
      Swal.fire({
        title: 'Entre com o E-mail do outro Usuário.',
        input: 'email',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Ir para compra',
        cancelButtonText:'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick:false,
        preConfirm: (login) => {
          return User.get_userid(login,Auth.token())
              .then(response => {
                if (!response.status == 200) {
                  throw new Error(response.statusText)
                }
                return response.data
              })
              .catch(error => {
                Swal.showValidationMessage(
                    `Request failed: ${error}`
                )
              })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if(result.value.id){
            this.order.user_id =result.value.id;
            this.order.birthday =result.value.birthday;
            this.user_age = this.get_age(this.order.birthday);

            this.verify_user();
          }else{
            Swal.fire({
              title: `Usuário não foi encontrado em nossa base de dados.`,
            }).then(()=>{
              this.to_other_user();
            });
          }
        }else if (result.isDismissed) {
              window.scrollTo(0, 0);
              this.$router.push('/minha-conta/');
        }
      })
    },
    verify_user(){
      let user_id = Auth.user().id;

      if( typeof this.order.user_id != "undefined"){
        user_id = this.order.user_id;
      }

      User.get_user_event(this.id,user_id,Auth.token()).then((request)=>{
        if(request.status == 200 && request.data == true){
          Swal.fire({
            title: 'Este Usuário já possui uma inscrição para este evento.',
            text:'Deseja comprar está inscrição para outro usuário ?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `SIM`,
            cancelButtonText:'NÃO'
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.to_other_user();
            } else if (result.isDismissed) {
              window.scrollTo(0, 0);
              this.$router.push('/minha-conta/');
            }
          })
        }else{
          this.validate_age(this.lotes);
          this.lotes.map((a,b) => {
            if(a.disabled == false){
                this.select_lote(a.id, b);
            }
          })
        }
      });
    },
    erro_agendamento(){
      Swal.fire({
        title: 'No momento não ha agendamentos disponíveis para esse evento.',
        text:'Para mais informações ou duvidas entre em contacto com nosso suporte.',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: `OK`,
        cancelButtonText:'NÃO'
      }).then(() => {
          window.scrollTo(0, 0);
          this.$router.push('/minha-conta/');
      });
    },
    validate_age(lote) {
      let temp = [];
      lote.map((value) => {
        if(value.age_max  >= this.user_age && value.age_min <= this.user_age){
          value.disabled = false;
        }else{
          value.disabled = true;
        }
        temp.push(value);
      });
      return temp;
    },
    get_age(birthday){
      let nwbirthday =  birthday.replaceAll('.000000Z','');
     // nwbirthday =  nwbirthday.replaceAll('T',' ');
      let age  = new Date() - new Date(nwbirthday);

      return  Math.floor((age / (1000 * 3600 * 24) )  / 365);
    },
    fetch_info() {

      if(window.localStorage.getItem('user_buy')){
        this.order.user_id = window.localStorage.getItem('user_buy');

        window.localStorage.removeItem('user_buy')
      }

      let User = Auth.user();
      this.user_age = this.get_age(User.birthday);

      this.order.event_id = null;
      this.lote_index = null;

      this.show_agendamento = false;

      Events.get_event(this.slug).then((request) => {
        if (request.status == 200) {
          this.event = request.data;

          this.order.event_id = this.event.id;
        } else {
          this.$router.push("/evento/nao-encontrado/");
        }

        Events.get_lotes(this.id, 1, Auth.token()).then((request) => {
          if (request.status == 200) {
            this.lotes = request.data;


            if(this.lotes.length == 0){
              return ;
            }

            this.lotes = this.validate_age(this.lotes);


          }
        });

        Events.get_agendamentos(this.id,Auth.token()).then((request) => {
          if (request.status == 200) {
            this.agendamentos = request.data.agendamentos;
            this.need_agendamento = request.data.required;

            if(this.agendamentos.length == 0 && this.need_agendamento == true){
                this.erro_agendamento();
            }
          }
        });

        this.verify_user();

        this.loading = false;
        this.order.termos = false;
        this.end_selections = false;
      });
    },
  },
  mounted() {

    this.order.termos = false;
    this.id = this.$route.params.id;
    this.slug = this.$route.params.slug;

    window.scrollTo(0, 0);

    this.loading = true;
    this.fetch_info();
  },
  updated() {
    if (this.slug != this.$route.params.slug) {
      this.slug = this.$route.params.slug;
      this.loading = true;
      this.fetch_info();
    }
  }
});
</script>

<style scoped>
.btn-proximo{
  color: #fff;
  background-color: #d31f28;
  border-color: #d31f28;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 18px;
}

.btn-proximo.active.focus, .btn-proximo.active:focus, .btn-proximo.active:hover,
 .btn-proximo:active.focus, .btn-proximo:active:focus, .btn-proximo:active:hover,
  .open>.dropdown-toggle.btn-proximo.focus, .open>.dropdown-toggle.btn-proximo:focus,
   .open>.dropdown-toggle.btn-proximo:hover {
    color: #fff;
  background-color: #d31f28;
  border-color: #d31f28;
}
img.img-card-kit{
  max-width: 100%;
}
img.capa-evento-view {
  width: 100%;
}

.box-patrocinadores a {
  width: 100%;
}

.box-patrocinadores a img {
  width: 100%;
}

.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.mb-4 {
  margin-bottom: 30px;
}

.mb-2 {
  margin-bottom: 15px;
}


.inscricoes.selected .card {
  background-color: #d31f28;
  color: #fff;
  transition: background-color 1s ease;
}

.inscricoes .card p {
  font-size: 20px;
}

.inscricoes .card {
  cursor: pointer;
}

.card .card-img-top {
  width: 100%;
}

.card .card-header {
  background-color: transparent;
  color: #fff;
  text-align: center;

  margin-bottom: -1px;
}

.card {
  border-radius: 7px;
}

.kits, .categoria, .final {
  animation: fade_in_show 1s
}

.card {
  transition: all .2s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.invalido input.form-control {
  border-color: #d31f28;
}

.btn-danger {
  color: #fff;
  background-color: #d31f28;
  border-color: #d31f28;
}

.card.card-default.disabled-card.disabled {
  background: #e7e7e7;
  cursor: default;
  pointer-events: none;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


</style>
